<template>
  <v-card flat>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-expansion-panels :value="[0, 1]" multiple>
            <v-expansion-panel v-if="showDMP">
              <v-expansion-panel-header hide-actions>
                <span><v-chip label color="ppDmp" dark>DMP</v-chip></span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-autocomplete
                  v-model="audience_ids"
                  :items="userInterests"
                  :label="
                    $t('components.transfer.stepInventory.form.interests.label')
                  "
                  :hint="
                    $t('components.transfer.stepInventory.form.interests.hint')
                  "
                  :loading="loadingAudiences"
                  name="assignedInterests"
                  item-text="text"
                  item-value="id"
                  multiple
                  chips
                  deletable-chips
                  multi-line
                >
                  <template #item="data">
                    <v-list-item-avatar>
                      <img
                        :src="
                          data.item.filename || helpers.getPlaceholderImage()
                        "
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>

                <v-autocomplete
                  v-model="audience_ids"
                  :items="userVisitorProperties"
                  :label="
                    $t(
                      'components.transfer.stepInventory.form.properties.label'
                    )
                  "
                  :hint="
                    $t('components.transfer.stepInventory.form.properties.hint')
                  "
                  :loading="loadingAudiences"
                  name="assignedProperties"
                  item-text="text"
                  item-value="id"
                  multiple
                  chips
                  deletable-chips
                  multi-line
                >
                  <template #item="data">
                    <v-list-item-avatar>
                      <img
                        :src="
                          data.item.filename || helpers.getPlaceholderImage()
                        "
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="showTools">
              <v-expansion-panel-header hide-actions>
                <span><v-chip label color="ppTools" dark>Tools</v-chip></span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-autocomplete
                  v-model="category_ids"
                  :items="categoryAudiences"
                  :label="
                    $t(
                      'components.transfer.stepInventory.form.categories.label'
                    )
                  "
                  :hint="
                    $t('components.transfer.stepInventory.form.categories.hint')
                  "
                  :loading="loadingAudiences"
                  name="assignedCategories"
                  item-text="text"
                  item-value="id"
                  multiple
                  chips
                  deletable-chips
                  multi-line
                >
                  <template #item="data">
                    <v-list-item-avatar>
                      <img
                        :src="
                          data.item.filename || helpers.getPlaceholderImage()
                        "
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12">
          <p>
            {{ $t('components.ads.targeting.hint' + (showDMP ? '' : 'NoDMP')) }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import helpers from '@/utils/helpers';

// helper for vuex-map-fields
const { mapFields } = createHelpers({
  getterType: 'ad/getField',
  mutationType: 'ad/updateField',
});

export default {
  components: {},
  data() {
    return {
      helpers,
      loading: false,
      loadingAudiences: false,
    };
  },
  computed: {
    ...mapGetters('audience', [
      'userInterests',
      'userVisitorProperties',
      'categoryAudiences',
    ]),
    ...mapFields([
      'ad.audience_ids',
      'ad.category_ids',
      'ad.id',
      'adTargetings',
    ]),
    showTools() {
      return this.$acl.check('useProductTools');
    },
    showDMP() {
      return this.$acl.check('viewAudiences');
    },
  },
  mounted() {
    this.getAudiences();
    this.$bus.listen('clear-data', this.clearData);
  },
  destroyed() {
    this.$bus.remove('clear-data', this.clearData);
  },
  methods: {
    async getAudiences() {
      this.loadingAudiences = true;
      await this.$store.dispatch('audience/getAudiences');
      this.loadingAudiences = false;
    },
    clearData() {
      this.audience_ids = [];
      this.category_ids = [];
    },
  },
};
</script>
