<template>
  <v-dialog
    :value="show"
    max-width="300"
    scrollable
    @keydown.esc.stop="closeDialog"
    @click:outside="closeDialog"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ $t('components.dialogDelete.title', { model: modelName }) }}
      </v-card-title>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <v-card-text
        v-html="
          $t(`components.dialogDelete.${questionString}`, {
            model: modelName,
            name: name,
          })
        "
      />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="closeDialog">
          {{ $t('components.dialogDelete.no') }}
        </v-btn>
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="error"
          text
          @click="confirm"
        >
          {{ $t('components.dialogDelete.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    modelName: {
      type: String,
      required: true,
      default: 'No Model',
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        useQuestionWithThis: false,
      }),
    },
  },
  data() {
    return {};
  },
  computed: {
    /**
     * Determine which translation question to use
     * @returns {string}
     */
    questionString() {
      if (this.options.useQuestionWithThis) {
        return 'questionWithThis';
      }
      return 'question';
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
    async confirm() {
      this.$emit('confirmed');
    },
  },
};
</script>

<style></style>
