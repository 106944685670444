<template>
  <v-card flat>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="mt-3 d-flex align-center">
          <v-btn-toggle
            v-model="adTypeToggler"
            mandatory
            rounded
            color="productColor"
            class="flex-grow-1"
          >
            <v-btn value="STATIC" v-if="!native">
              {{
                $vuetify.breakpoint.xsOnly
                  ? $t('components.ads.detail.type.staticXs')
                  : $t('components.ads.detail.type.static')
              }}
            </v-btn>
            <v-btn value="DYNAMIC">
              {{
                $vuetify.breakpoint.xsOnly
                  ? $t('components.ads.detail.type.dynamicXs')
                  : $t('components.ads.detail.type.dynamic')
              }}
            </v-btn>
            <v-btn value="GOOGLE">
              {{ $t('components.ads.detail.googleAd') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12">
          <template v-if="isStatic">
            <v-col class="align-center justify-space-between" cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                :name="$t('components.ads.detail.teaser')"
                vid="headline"
                rules="max:100"
              >
                <v-text-field
                  v-model="headline"
                  :counter="100"
                  :label="$t('components.ads.detail.teaser')"
                  :error-messages="errors"
                  :loading="loading"
                  name="headline"
                />
              </ValidationProvider>
            </v-col>
            <v-col class="align-center justify-space-between" cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                :name="$t('components.ads.detail.cta')"
                vid="cta_label"
                rules="max:25"
              >
                <v-text-field
                  v-model="cta_label"
                  :counter="25"
                  :label="$t('components.ads.detail.cta')"
                  :error-messages="errors"
                  :loading="loading"
                  name="cta_label"
                />
              </ValidationProvider>
            </v-col>
            <v-col class="align-center justify-space-between" cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                :name="$t('components.ads.detail.targetUrl')"
                vid="target_url"
                rules="required|url|max:2000"
              >
                <v-text-field
                  v-model="target_url"
                  :counter="2000"
                  :label="$t('components.ads.detail.targetUrl')"
                  :error-messages="errors"
                  :loading="loading"
                  name="target_url"
                  @change="addHttpsPrefix"
                />
              </ValidationProvider>
            </v-col>
            <v-col class="align-center justify-space-between" cols="12">
              <ad-image-fields />
            </v-col>
          </template>
          <template v-else>
            <v-col v-if="ad.settings.isGoogleAd" cols="12" class="mb-4">
              <div v-if="isInternalUser" class="d-flex align-center">
                <v-text-field
                  v-model="ad.settings.scriptSrc"
                  clearable
                  :label="$t('components.ads.detail.scriptSrc')"
                  placeholder="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
                  :loading="loading"
                  name="scriptSrc"
                />
                <v-text-field
                  class="ml-4"
                  v-model="ad.settings.domain"
                  clearable
                  :label="$t('components.ads.detail.domain')"
                  :loading="loading"
                  name="domain"
                />
                <v-text-field
                  class="ml-4"
                  v-model="ad.settings.value"
                  clearable
                  :label="$t('components.ads.detail.value')"
                  :loading="loading"
                  name="value"
                />
              </div>

              <div class="d-flex align-center">
                <v-text-field
                  v-model="ad.settings.inline.path"
                  clearable
                  :label="$t('components.ads.detail.path')"
                  :loading="loading"
                  name="path"
                />
                <v-text-field
                  class="ml-4"
                  v-model="ad.settings.inline.sizes"
                  :disabled="ad.settings.inline.freeSizes"
                  :label="$t('components.ads.detail.sizes')"
                  :loading="loading"
                  name="sizes"
                  @input="ad.settings.inline.formatSizes()"
                  @blur="ad.settings.inline.trimSizes()"
                />
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      small
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :color="
                        ad.settings.inline.freeSizes ? 'productColor' : 'grey'
                      "
                      @click="ad.settings.inline.toggleFreeSizes()"
                    >
                      <v-icon>mdi-fit-to-page-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t(
                      'components.ads.detail.freeSizesTooltip' +
                        (ad.settings.inline.freeSizes ? 'Enabled' : '')
                    )
                  }}</span>
                </v-tooltip>
              </div>
              <div class="d-flex align-center">
                <v-text-field
                  v-model="ad.settings.inline.elementId"
                  clearable
                  :label="$t('components.ads.detail.elementId')"
                  :loading="loading"
                  placeholder="gpt-passback-%random%"
                  name="elementId"
                />
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small
                      icon
                      :color="
                        ad.settings.inline.randomOn ? 'productColor' : 'grey'
                      "
                      @click="ad.settings.inline.toggleRandom()"
                    >
                      <v-icon>mdi-shuffle</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t(
                      'components.ads.detail.randomTooltip' +
                        (ad.settings.inline.randomOn ? 'Enabled' : '')
                    )
                  }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small
                      icon
                      :color="
                        ad.settings.inline.iterateOn ? 'productColor' : 'grey'
                      "
                      @click="ad.settings.inline.toggleIterate()"
                    >
                      <v-icon>mdi-repeat</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t(
                      'components.ads.detail.iterateTooltip' +
                        (ad.settings.inline.iterateOn ? 'Enabled' : '')
                    )
                  }}</span>
                </v-tooltip>

                <v-btn
                  class="ml-4"
                  color="success"
                  @click="parseFromGPTShowDialog()"
                >
                  <v-icon>mdi-auto-fix</v-icon>
                  {{ $t('components.ads.detail.parseFromGPT') }}
                </v-btn>
              </div>
              <template v-if="native">
                <v-switch
                  v-model="ad.settings.floating"
                  :label="$t('components.ads.detail.floating')"
                />
                <div v-if="!!ad.settings.floating" class="d-flex">
                  <v-text-field
                    min="0"
                    placeholder="none"
                    v-model="ad.settings.bg"
                    :label="$t('components.ads.detail.background')"
                    :loading="loading"
                    name="bg"
                  />
                  <v-text-field
                    type="number"
                    class="ml-4"
                    min="0"
                    placeholder="150"
                    v-model="ad.settings.adMinH"
                    :label="$t('components.ads.detail.minimalHeight')"
                    :loading="loading"
                    name="adMinH"
                  />
                </div>
                <div v-if="ad.settings.floating" class="d-flex">
                  <v-textarea
                    v-model="ad.settings.expandButton"
                    clearable
                    :label="$t('components.ads.detail.expandButton')"
                    :loading="loading"
                    name="expandButton"
                  />
                  <div
                    class="col-1"
                    style="position: relative"
                    v-if="ad.settings.expandButton"
                  >
                    <div
                      class="pinpoll-ad-expand-btn"
                      v-html="ad.settings.expandButton"
                    ></div>
                  </div>
                </div>
                <v-switch
                  v-model="ad.settings.interstitialOn"
                  :label="$t('components.ads.detail.interstitial')"
                />
                <template
                  v-if="ad.settings.interstitialOn && ad.settings.interstitial"
                >
                  <div class="d-flex align-center">
                    <v-text-field
                      v-model="ad.settings.interstitial.path"
                      clearable
                      :label="$t('components.ads.detail.path')"
                      :loading="loading"
                      name="interstitialPath"
                    />
                    <v-text-field
                      v-model="ad.settings.interstitial.sizes"
                      class="ml-4"
                      :disabled="ad.settings.interstitial.freeSizes"
                      :label="$t('components.ads.detail.sizes')"
                      :loading="loading"
                      name="interstitialSizes"
                      @input="ad.settings.interstitial.formatSizes()"
                      @blur="ad.settings.interstitial.trimSizes()"
                    />
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          :color="
                            ad.settings.interstitial.freeSizes
                              ? 'productColor'
                              : 'grey'
                          "
                          @click="ad.settings.interstitial.toggleFreeSizes()"
                        >
                          <v-icon>mdi-fit-to-page-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        $t(
                          'components.ads.detail.freeSizesTooltip' +
                            (ad.settings.interstitial.freeSizes
                              ? 'Enabled'
                              : '')
                        )
                      }}</span>
                    </v-tooltip>
                    <v-text-field
                      type="number"
                      class="ml-4"
                      min="0"
                      v-model="ad.settings.interstitial.duration"
                      :label="$t('components.ads.detail.duration')"
                      :loading="loading"
                      name="interstitialDuration"
                    />
                  </div>
                  <div class="d-flex align-center">
                    <v-text-field
                      v-model="ad.settings.interstitial.elementId"
                      clearable
                      :label="$t('components.ads.detail.elementId')"
                      :loading="loading"
                      placeholder="gpt-passback-%random%"
                      name="interstitialElementId"
                    />
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          small
                          icon
                          :color="
                            ad.settings.interstitial.randomOn
                              ? 'productColor'
                              : 'grey'
                          "
                          @click="ad.settings.interstitial.toggleRandom()"
                        >
                          <v-icon>mdi-shuffle</v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        $t(
                          'components.ads.detail.randomTooltip' +
                            (ad.settings.interstitial.randomOn ? 'Enabled' : '')
                        )
                      }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          small
                          icon
                          :color="
                            ad.settings.interstitial.iterateOn
                              ? 'productColor'
                              : 'grey'
                          "
                          @click="ad.settings.interstitial.toggleIterate()"
                        >
                          <v-icon>mdi-repeat</v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        $t(
                          'components.ads.detail.iterateTooltip' +
                            (ad.settings.interstitial.iterateOn
                              ? 'Enabled'
                              : '')
                        )
                      }}</span>
                    </v-tooltip>
                    <v-btn
                      class="ml-4"
                      color="success"
                      @click="parseFromGPTShowDialog(true)"
                    >
                      <v-icon>mdi-auto-fix</v-icon>
                      {{ $t('components.ads.detail.parseFromGPT') }}
                    </v-btn>
                  </div>
                </template>
              </template>

              <v-dialog :value="parseFromGPTShow" scrollable max-width="800px">
                <v-card>
                  <v-card-text>
                    <v-textarea
                      v-model="parseFromGPTInput"
                      clearable
                      :rows="6"
                      :label="$t('components.ads.detail.parseFromGPT')"
                      :loading="loading"
                      name="parseFromGPTInput"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      :loading="loading"
                      :disabled="loading"
                      color="warning"
                      text
                      @click="parseFromGPTShow = false"
                      >{{ $t('common.actions.cancel') }}</v-btn
                    >
                    <v-btn
                      :loading="loading"
                      :disabled="loading"
                      color="success"
                      text
                      @click="parseFromGPT(parseFromGPTShow)"
                      >OK</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-textarea
              v-show="native && !ad.settings.isGoogleAd"
              v-model="nativeTag"
              clearable
              :rows="6"
              :label="$t('components.ads.detail.nativeTag')"
              :loading="loading"
              name="nativeTag"
            />
            <ValidationProvider
              v-slot="{ errors, changed }"
              :name="
                $t(
                  'components.ads.detail.tag' +
                    (ad.settings.isGoogleAd ? 'GoogleAd' : '')
                )
              "
              vid="tag"
              :rules="{ required: !native }"
            >
              <v-textarea
                v-model="tag"
                clearable
                :rows="6"
                :label="
                  $t(
                    'components.ads.detail.tag' +
                      (ad.settings.isGoogleAd ? 'GoogleAd' : '')
                  )
                "
                :error-messages="errors"
                :loading="loading"
                name="tag"
              />

              <template v-if="!changed && ad.approved">
                <v-alert
                  outlined
                  color="success"
                  icon="mdi-check-circle"
                  class="mt-4"
                >
                  <strong>{{
                    $t('components.ads.detail.alert.approved')
                  }}</strong>
                </v-alert>
              </template>
              <template v-else>
                <v-alert
                  outlined
                  color="primary"
                  icon="mdi-exclamation-thick"
                  class="mt-4"
                >
                  <div>{{ $t('components.ads.detail.alert.warning') }}</div>
                  <strong v-if="editMode">
                    {{ $t('components.ads.detail.alert.notApproved') }}
                  </strong>
                </v-alert>
              </template>
            </ValidationProvider>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapState } from 'vuex';
import AdImageFields from './AdImageFields';

// helper for vuex-map-fields
const { mapFields } = createHelpers({
  getterType: 'ad/getField',
  mutationType: 'ad/updateField',
});

export default {
  components: {
    AdImageFields,
  },
  props: {
    editMode: {
      type: Boolean,
      required: true,
      default: false,
    },
    /**
     * Current Loading indicator
     */
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      adTypeToggler: 'STATIC',
      target_url: '',
      parseFromGPTShow: false,
      parseFromGPTInput: '',
    };
  },
  computed: {
    ...mapFields([
      'ad.isStatic',
      'ad.headline',
      'ad.cta_label',
      'ad.tag',
      'ad.nativeTag',
      'ad.native',
      'ad.settings',
    ]),
    ...mapState('ad', ['ad']),
    isAdmin() {
      return this.$acl.check('viewModuleAdmin');
    },
    isInternalUser() {
      return !!this.$store.state.account.user?.roles?.find(
        (i) => i.is_internal === '1'
      );
    },
  },
  watch: {
    ad: {
      immediate: true,
      deep: true,
      handler(changedAd) {
        // only init if there is an id in the ad object
        if (changedAd.id) {
          this.initData();
        }
      },
    },
    adTypeToggler: {
      immediate: false,
      handler() {
        if (this.adTypeToggler === 'STATIC') {
          this.isStatic = true;
          this.ad.settings?.disable();
        } else if (this.adTypeToggler === 'DYNAMIC') {
          this.isStatic = false;
          this.ad.settings?.disable();
        } else if (this.adTypeToggler === 'GOOGLE') {
          this.isStatic = false;
          this.ad.settings?.enable();
        }
      },
    },
  },
  mounted() {
    this.$bus.listen('clear-data', this.clearData);
    this.adTypeToggler = this.isStatic
      ? 'STATIC'
      : (this.ad.settings.isGoogleAd && 'GOOGLE') || 'DYNAMIC';
  },
  destroyed() {
    this.$bus.remove('clear-data', this.clearData);
  },
  methods: {
    addHttpsPrefix() {
      if (
        !this.target_url.startsWith('http://') &&
        !this.target_url.startsWith('https://')
      ) {
        this.target_url = `https://${this.target_url}`;
      }
      this.$store.commit('ad/SET_TARGET_URL', this.target_url);
    },
    initData() {
      this.target_url = this.ad.target_url;
    },
    clearData() {
      this.target_url = '';
      this.$bus.fire('clear-image');
    },
    parseFromGPTShowDialog(interstitial) {
      this.parseFromGPTInput = '';
      this.parseFromGPTShow = interstitial ? 'interstitial' : 'inline';
    },
    parseFromGPT(settingsAd) {
      const scriptSrcMatch = this.parseFromGPTInput.match(
        /<script[^>]*src="([^"]+)"[^>]*><\/script>/
      );
      if (scriptSrcMatch) {
        this.ad.settings.scriptSrc = scriptSrcMatch[1];
      }
      const elementIdMatch = this.parseFromGPTInput.match(/id="([^"]+)"/);
      if (elementIdMatch) {
        this.ad.settings[settingsAd].elementId = elementIdMatch[1];
      }
      const defineSlotMatch = this.parseFromGPTInput.match(
        /googletag\.defineSlot\('([^']+)'\s*,\s*\[(\d+),\s*(\d+)\]\s*,\s*'([^']+)'\)/
      );
      if (defineSlotMatch) {
        let slotString = defineSlotMatch[1];
        // eslint-disable-next-line no-useless-escape
        let domainMatch = slotString.match(/\/([^\/]*\.[^\/]*)\//);
        if (domainMatch) {
          this.ad.settings.domain = domainMatch[1];
        }
        this.ad.settings[settingsAd].path = slotString;
        this.ad.settings[settingsAd].sizes = [
          parseInt(defineSlotMatch[2]),
          parseInt(defineSlotMatch[3]),
        ];
      }
      this.parseFromGPTShow = false;
    },
    toggleElementIdParameter(parameter, interstitial) {
      const settingsAd = interstitial ? 'interstitial' : 'inline';
      if (this.ad.settings[settingsAd].elementId.includes(`%${parameter}%`)) {
        this.ad.settings[settingsAd].elementId = this.ad.settings[
          settingsAd
        ].elementId.replace(`%${parameter}%`, '');
      } else {
        this.ad.settings[settingsAd].elementId =
          this.ad.settings[settingsAd].elementId + `%${parameter}%`;
      }
    },
  },
};
</script>

<style scoped>
.delete-image {
  float: right;
  padding-right: 10px;
  padding-top: 10px;
  cursor: pointer;
}

.dropzone-field-smaller-width {
  width: 95%;
}
</style>
